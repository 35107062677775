// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gallery-js": () => import("/opt/build/repo/src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-registry-cancelled-js": () => import("/opt/build/repo/src/pages/registry/cancelled.js" /* webpackChunkName: "component---src-pages-registry-cancelled-js" */),
  "component---src-pages-registry-index-js": () => import("/opt/build/repo/src/pages/registry/index.js" /* webpackChunkName: "component---src-pages-registry-index-js" */),
  "component---src-pages-registry-success-js": () => import("/opt/build/repo/src/pages/registry/success.js" /* webpackChunkName: "component---src-pages-registry-success-js" */),
  "component---src-pages-rsvp-failed-js": () => import("/opt/build/repo/src/pages/rsvp/failed.js" /* webpackChunkName: "component---src-pages-rsvp-failed-js" */),
  "component---src-pages-rsvp-index-js": () => import("/opt/build/repo/src/pages/rsvp/index.js" /* webpackChunkName: "component---src-pages-rsvp-index-js" */),
  "component---src-pages-rsvp-success-js": () => import("/opt/build/repo/src/pages/rsvp/success.js" /* webpackChunkName: "component---src-pages-rsvp-success-js" */),
  "component---src-pages-schedule-js": () => import("/opt/build/repo/src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-travel-js": () => import("/opt/build/repo/src/pages/travel.js" /* webpackChunkName: "component---src-pages-travel-js" */)
}

